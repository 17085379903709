@font-face {
    font-family: RobotoCondensed;
    src: url(assets/RobotoCondensed-Light.ttf);
    font-weight: 800;
  }
  
  @font-face {
    font-family: EconomicaBold;
    src: url(assets/Economica-Bold.ttf);
  }
  
  body {
    text-align: center;
    font-size: 20px;
    padding: 2.5%;
    font-family: 'RobotoCondensed';
  }
  
  h1, h2, h3, h4, h5 {
    font-family: 'EconomicaBold';
  }